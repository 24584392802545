import React, {useContext, useEffect, useState} from 'react';
import './MaxBet.scss';
import {AppContext} from "../../../App";
import {useTranslation} from "react-i18next";
import RequestManager from "../../../services/RequestManager";
import {MAX_BET_DATA, RE_CAPTCHA_DATA, RE_CAPTCHA_MAX_DATA} from "../../../services/RequestDatas";
import Keyboard from "../../Keyboard/Keyboard";
import Utils from '../../../utils/Utils';

function MaxBet({totalCoef, betSetting, placeBetsCallback, isFailed, currentCoef}) {
  const {t} = useTranslation();
  const context = useContext(AppContext);
  const socket = RequestManager.getInstance().webSocket;

  const balance = context.balance.get;
  const betsData = context.betsData.get;
  const userLoggedIn = context.userLoggedIn.get;
  const userBetType = +context.userPreferBetType.get;
  const isTaxCase = context.partnerConfig.get['tax_integration_type'] === 1;
  const currency = context.currency.get;
  const minBet = context.partnerConfig.get['min_bet_stakes'][currency];
  const MIN_BET_VALUE = minBet || 0;

  const [betValue, setBetValue] = useState(MIN_BET_VALUE * 2);
  const [warnings, setWarnings] = useState([]);
  const [fadeOut, setFadeOut] = useState(null);
  const [placeProcess, setPlaceProcess] = useState(false);

  useEffect(() => {
    context.activeStakeInput.set(null);
  }, [userBetType])

  useEffect(() => {
    const currentWarnings = [...warnings];
    const deletedItem = t('deleted events');
    if (!isFailed) {
      if (currentWarnings.indexOf(deletedItem) !== -1) {
        currentWarnings.splice(currentWarnings.indexOf(deletedItem), 1);
      }
    } else {
      currentWarnings.push(deletedItem);
    }

    setWarnings([...new Set([...currentWarnings])]);

  }, [isFailed, betsData, currentCoef, balance, context.language.get])

  useEffect(() => {
    let arr = Array.from(warnings);
    if (arr.length > 1) {
      setTimeout(() => {
        setFadeOut('fadeOut');

        setTimeout(() => {
          setFadeOut(null);
          setWarnings([]);
        }, 3000)
      }, 1000)
    }
  }, [warnings])

  const checkBalance = value => {
    const balance = context.balance.get;
    const currentWarnings = [...warnings];
    const correctBetValue = value <= balance;
    const correctMinBet = value >= minBet;
    const thereIsWarning = value && (value > balance || value < minBet);
    const balanceWarning = t('insufficient balance');
    const minBetWarning = t('amount is less');
    const deletedItem = t('deleted events');
    const betIsLess = `${minBetWarning} ( ${minBet} ${currency} ).`;

    if (isFailed) {
      currentWarnings.push(deletedItem);
    } else {
      if (thereIsWarning) {
        if (!correctBetValue && value !== '') {
          currentWarnings.push(balanceWarning);
        }
        if (!correctMinBet && value !== '') {
          currentWarnings.push(betIsLess);
        }
      } else {
        if (correctBetValue || value === '') {
          const balanceWarningIndex = currentWarnings.indexOf(balanceWarning);
          currentWarnings.splice(balanceWarningIndex, 1);
        }
        if (correctMinBet || value === '') {
          const minBetWarningIndex = currentWarnings.indexOf(betIsLess);
          currentWarnings.splice(minBetWarningIndex, 1);
        }
      }
    }

    setWarnings([...new Set([...currentWarnings])]);
  }

  const changeBetValue = value => {

    if (typeof value === 'object') {
      value = value.currentTarget.value
    }

    // TODO needs to check.
    //checkBalance(value);
    updateBetValue(value);
  }

  const prepareToGetMaxBetValue = () => {
    const reCaptchaData = context.reCaptchaData.get;
    if (reCaptchaData && reCaptchaData['version'] === 3) {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=" + context.reCaptchaData.get.siteKey;
      script.addEventListener("load", handleMaxBetReCaptchaScriptLoaded);

      document.body.appendChild(script);
    } else {
      getMaxBetValue();
    }
  }

  const updateMaxBetValue = ({data}) => {
    if (data && data.details?.amount) {
      changeBetValue(data.details.amount);
    }
  }

  const handleMaxBetReCaptchaScriptLoaded = data => {
    window.grecaptcha.ready(data => {
      window.grecaptcha.execute(context.reCaptchaData.get.siteKey, {action: "get_max_bet"}).then(token => RE_CAPTCHA_MAX_DATA.params.g_recaptcha_response = token)
    })

    socket.send(JSON.stringify(RE_CAPTCHA_MAX_DATA));
    socket.addEventListener(RequestManager.RE_CAPTCHA_MAX_EVENT, response => handleMaxReCaptchaRequestResponse(response.detail), {once: true});
  }

  const handleMaxReCaptchaRequestResponse = response => {
    // TODO needs to check response status.
    getMaxBetValue();
  }

  const getMaxBetValue = () => {
    const betItemIDs = context.betsData.get.map(item => item['id']);
    MAX_BET_DATA.params.type = userBetType+ 1;
    MAX_BET_DATA.params.events = betItemIDs;

    socket.send(JSON.stringify(MAX_BET_DATA));
    socket.addEventListener(RequestManager.MAX_BET_EVENT, response => updateMaxBetValue(response.detail), {once: true});
  }

  const placeBets = () => {
    const minBetWarning = t('amount is less');

    if (userBetType === 2) {

      placeBetsCallback(betValue * currentCoef);

    } else if (userBetType === 1) {

      placeBetsCallback(betValue);

    } else {
      const currentWarnings = [...warnings];
      const betIsLess = `${minBetWarning} ( ${minBet} ${currency} ).`;
      const balanceWarning = t('insufficient balance');
      const balanceWarningIndex = currentWarnings.indexOf(balanceWarning);
      const warningIndex = warnings.indexOf(betIsLess);
      let isValid = true;

      for (let el of betsData) {

        if (el.betValue === undefined
          || el.betValue === ""
          || (el.betValue < MIN_BET_VALUE && warningIndex === -1)
          || (el.betValue > balance && balanceWarningIndex === -1)) {

          if (!el.betValue || el.betValue < MIN_BET_VALUE && warningIndex === -1) {
            currentWarnings.push(betIsLess);
          }

          if (el.betValue > balance && balanceWarningIndex === -1) {
            currentWarnings.push(balanceWarning);
          }

          setWarnings(currentWarnings);
          isValid = false;
          break;
        }
      }

      if (isValid) {
        placeBetsCallback(null);
      }

      setTimeout(() => setWarnings([]), 2000);
    }

    setPlaceProcess(false);
  }


  const checkPlaceBetIsEnabled = () => {
    let enabled = betValue && !warnings.length && context.userLoggedIn.get;
    enabled = userBetType === 1 ? enabled : true;

    if (userBetType === 2) {
      enabled = !warnings.length && context.userLoggedIn.get && betValue && currentCoef && totalCoef;
    } else if (userBetType === 0) {
      enabled = !warnings.length && context.userLoggedIn.get && betsData.some(item => item.betValue)
    }

    if (placeProcess || betsData.some(item => item.status === 'failed') || context.userLoggedIn.get === false) {
      enabled = false;
    }

    return enabled
  }

  const getPlaceBetBlock = () => {
    // TODO needs to improvements.
    const enabled = checkPlaceBetIsEnabled()

    const className = enabled ? 'place-bet enabled' : 'place-bet';
    const showKeyboard = userLoggedIn && context.activeStakeInput.get;

    return <div className={showKeyboard ? 'place-bet-container long' : 'place-bet-container'}>
      <div className="container">
        {showKeyboard ? <Keyboard changeCallBack={value => updateBetValue(value)}/> : null}
        <div className={className}
             onClick={() => userLoggedIn && className.includes('enabled') ? checkRecaptcha() : null}>{
          betSetting === 'always-ask' ? t(betSetting).toUpperCase() :
            t('place bet').toUpperCase()}</div>
        {
          warnings.map((warningMessage, index) => {
            return <div className={`bet-warning ${fadeOut}`} key={index}>{warningMessage}</div>
          })
        }
      </div>
    </div>
  }

  const checkRecaptcha = () => {
    if (placeProcess) {
      return;
    }

    setPlaceProcess(true);

    const reCaptchaData = context.reCaptchaData.get;
    if (reCaptchaData && reCaptchaData['version'] === 3) {
      initReCaptchaScript();
    } else {
      placeBets();
    }
  }

  const initReCaptchaScript = () => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=" + context.reCaptchaData.get.siteKey;
    script.addEventListener("load", handleReCaptchaScriptLoaded);

    document.body.appendChild(script);
  }

  const handleReCaptchaScriptLoaded = data => {
    window.grecaptcha.ready(data => {
      window.grecaptcha.execute(context.reCaptchaData.get.siteKey, {action: "do_bet"}).then(token => RE_CAPTCHA_DATA.params.g_recaptcha_response = token)
    })

    socket.send(JSON.stringify(RE_CAPTCHA_DATA));
    socket.addEventListener(RequestManager.RE_CAPTCHA_EVENT, response => handleReCaptchaRequestResponse(response.detail), {once: true});
  }

  const handleReCaptchaRequestResponse = () => {
    // TODO needs to check response status.
    placeBets();
  }

  const getStakeTaxPercentage = () => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const selectedTaxRange = taxAmountRanges.filter(item => {
      return (betValue >= item['MinAmount'] && betValue <= item['MaxAmount'] && item['TaxType'] === 4)
    })[0];

    return selectedTaxRange ? selectedTaxRange['TaxPrecentage'] : 0;
  }

  const getTaxedPossibleWin = () => {
    const taxStakePercentage = getStakeTaxPercentage();
    return (totalCoef * (betValue - (betValue * taxStakePercentage / 100))).toFixed(2);
  }

  const getWinTaxPercentage = () => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const possibleWin = (totalCoef * betValue).toFixed(2);
    const selectedWinTaxRange = taxAmountRanges.filter(item => {
      return (possibleWin >= item['MinAmount'] && possibleWin <= item['MaxAmount'] && item['TaxType'] === 2)
    })[0];

    return selectedWinTaxRange ? selectedWinTaxRange['TaxPrecentage'] : null;
  }

  const getTaxWinInfo = () => {
    if (isTaxCase === false || !betValue) {
      return false;
    }

    const taxWinPercentage = getWinTaxPercentage();
    const possibleWin = getTaxedPossibleWin();

    return taxWinPercentage ? <div className='tax-info'>
      <div className="win-info">
        <p>{t('tax amount') + '( ' + taxWinPercentage + '%)'}</p>
        <p>{(possibleWin * taxWinPercentage / 100).toFixed(2)}</p>
      </div>
      <div className="win-info">
        <p>{t('final payout')}</p>
        <p>{(possibleWin - (possibleWin * taxWinPercentage / 100)).toFixed(2)}</p>
      </div>
    </div> : null
  }

  const getProfitTaxPercentage = taxedPossibleWin => {
    const taxAmountRanges = context.partnerConfig.get['tax_amount_ranges'];
    const taxStakePercentage = getStakeTaxPercentage();
    const calcTaxedPossibleWin = taxedPossibleWin - (betValue - betValue * taxStakePercentage / 100).toFixed(2);

    const selectedTaxRange = taxAmountRanges.filter(item => {
      return (calcTaxedPossibleWin >= item['MinAmount'] && calcTaxedPossibleWin <= item['MaxAmount'] && item['TaxType'] === 1)
    })[0];

    return selectedTaxRange ? selectedTaxRange['TaxPrecentage'] : 0;
  }

  const getTaxStakeInfo = () => {
    if (isTaxCase === false || !betValue) {
      return false;
    }

    const taxStakePercentage = getStakeTaxPercentage();
    const totalAmount = (betValue - betValue * taxStakePercentage / 100).toFixed(2);
    const taxedPossibleWin = getTaxedPossibleWin();
    const profitTaxPercentage = getProfitTaxPercentage(taxedPossibleWin);

    let taxedTotalProfit;
    if (profitTaxPercentage) {
      taxedTotalProfit = (taxedPossibleWin - totalAmount) * profitTaxPercentage / 100;
    }

    return taxStakePercentage ? <div className='tax-info'>
      <div className="win-info">
        <p>{t('tax amount') + ' (' + taxStakePercentage + '%)'}</p>
        <p>{betValue * taxStakePercentage / 100}</p>
      </div>
      <div className="win-info">
        <p>{t('total bet amount')}</p>
        <p>{totalAmount}</p>
      </div>
      {
        taxedTotalProfit ? <div className="win-info">
          <p>Tax to be paid to RRA:</p>
          <p>{taxedTotalProfit}</p>
        </div> : null
      }
      {
        taxedTotalProfit ? <div className="win-info">
          <p>Final payout:</p>
          <p>{Number(taxedPossibleWin) - Number(taxedTotalProfit)}</p>
        </div> : null
      }
    </div> : null
  }

  const getPotentialWinningBlock = () => {
    return warnings.length > 0 || (userBetType === 2 && !currentCoef) || userBetType === 0 ? null :
      <div className='potential-winning-container'>
        <p className='potential-win-title'>{t('possible win')}:</p>
        <p className='potential-win-value'>
          {`${isNaN((totalCoef * betValue).toFixed(2)) ? `0.00 ` : (totalCoef * betValue).toFixed(2)} 
          ${context.userLoggedIn.get ? currency : context.partnerConfig.get['currency']}`}</p>
        {getTaxWinInfo()}
        {getTaxStakeInfo()}
      </div>
  }

  const updateBetValue = value => {
    value = Utils.validInputValue(value)
    setBetValue(value);
  }

  const getMinBetValue = () => {
    const value = Number(MIN_BET_VALUE);
    updateBetValue(value);
  }

  const minusMin = () => {
    if (betValue > MIN_BET_VALUE) {
      const value = Number(betValue) - Number(MIN_BET_VALUE);
      updateBetValue(value);
    }
  }

  const plusMin = () => {
    const value = Number(betValue) + MIN_BET_VALUE;
    updateBetValue(value);
  }

  const doubleUp = () => {
    if (betValue) {
      const value = betValue * 2;
      updateBetValue(value);
    }
  }

  const divideValue = () => {

    if (betValue && betValue && (betValue / 2 >= MIN_BET_VALUE)) {
      const value = betValue / 2;
      updateBetValue(value);
    }
  }

  const activateInput = e => {
    context.activeStakeInput.set(e.currentTarget);
  }


  return betsData.length ? <div className='max-bet-container'>
    {
      userBetType === 1   ?
        <div className="stake-block">
          <div className="stake-button max-button" onClick={getMinBetValue}>Min</div>
          <div className="stake-button" onClick={divideValue}>1/2</div>
          <div className="stake-button minus" onClick={minusMin}>-</div>
          <input type="text" placeholder={t('stake')} readOnly onClick={activateInput} value={betValue}/>
          <div className="stake-button plus" onClick={plusMin}>+</div>
          <div className="stake-button" onClick={doubleUp}>x2</div>
          <div className="stake-button min-button" onClick={prepareToGetMaxBetValue}>Max</div>
        </div>

        : userBetType === 2 && currentCoef ?
        <>
          <div className='system-calculator-block'>
            <div className='system-coef'><span>{currentCoef}</span> X</div>
            <div className="stake-block">
              <div className="stake-button max-button" onClick={getMinBetValue}>Min</div>
              <div className="stake-button" onClick={divideValue}>1/2</div>
              <div className="stake-button minus" onClick={minusMin}>-</div>
              <input type="text" placeholder={t('stake')} readOnly onClick={activateInput} value={betValue}/>
              <div className="stake-button plus" onClick={plusMin}>+</div>
              <div className="stake-button" onClick={doubleUp}>x2</div>
              <div className="stake-button min-button" onClick={prepareToGetMaxBetValue}>Max</div>
            </div>
          </div>
        </>
        : null
    }
    {getPotentialWinningBlock()}
    {getPlaceBetBlock()}
  </div> : null
}

export default MaxBet;
