import {
  AUTHORIZATION_RID,
  AUTO_DATA_UPDATE_RID,
  BET_HISTORY_RID,
  CASH_OUT_RID,
  CASH_OUT_SUBSCRIBE_DATA,
  CHANGE_LANG_RID,
  CHECK_BET_RID,
  CURRENT_SPORT_RID, GAME_MATCH_ID_RESULT,
  GAME_RESULTS_RID,
  GAME_RID,
  LEAGUE_ROUNDS_RID,
  LOGIN_DATA,
  MARKET_RID,
  MAX_BET_RID,
  OPENED_BETS_COUNT_RID,
  OPENED_BETS_RID,
  PARTNER_CONFIG_RID,
  PLACE_BETS_RID,
  PROFILE_RID,
  RE_CAPTCHA_DATA_RID,
  RE_CAPTCHA_MAX_DATA_RID,
  REGION_RID,
  SPORTS_RID,
  SWARM_LOGIN_RID,
  TENNIS_RID,
  UNSUBSCRIBE_RID
} from './RequestDatas';
import Utils from "../utils/Utils";

const SOCKET_URL = 'wss://eu-swarm-virtual-ws.deimosphobos.net/';
const OLD_SOCKET_URL = 'wss://eu-swarm-virtual-ws.betconstruct.com/';
const SOCKET_URL_STAGING = 'wss://swarm-virtuals-staging.betconstruct.com/';

let webSocket;

export default class RequestManager {
  static SWARM_LOGIN_EVENT = 'SWARM_LOGIN_EVENT';
  static AUTHORIZATION_EVENT = 'AUTHORIZATION_EVENT';
  static CHANGE_LANG_EVENT = 'CHANGE_LANG_EVENT';
  static RE_CAPTCHA_EVENT = 'RE_CAPTCHA_EVENT';
  static RE_CAPTCHA_MAX_EVENT = 'RE_CAPTCHA_MAX_EVENT';
  static SOCKET_CLOSED_EVENT = 'SOCKET_CLOSED_EVENT';
  static PARTNER_DATA_EVENT = 'PARTNER_DATA_EVENT';
  static SPORTS_DATA_EVENT = 'SPORTS_DATA_EVENT';
  static REGION_DATA_EVENT = 'REGION_DATA_EVENT';
  static PROFILE_DATA_EVENT = 'PROFILE_DATA_EVENT';
  static AUTO_BALANCE_DATA = 'AUTO_BALANCE_DATA';
  static SPORT_DATA_UPDATED_EVENT = 'SPORT_DATA_UPDATED_EVENT';
  static GAME_DATA_EVENT = 'GAME_DATA_EVENT';
  static GAME_RESULTS_UPDATE_EVENT = 'GAME_RESULTS_UPDATE_EVENT';
  static MARKET_DATA_EVENT = 'MARKET_DATA_EVENT';
  static LEAGUE_ROUNDS_DATA_EVENT = 'LEAGUE_ROUNDS_DATA_EVENT';
  static BET_CHECKED_EVENT = 'BET_CHECKED_EVENT';
  static PLACE_BETS_EVENT = 'PLACE_BETS_EVENT';
  static BET_HISTORY_EVENT = 'BET_HISTORY_EVENT';
  static TENNIS_DATA_EVENT = 'TENNIS_DATA_EVENT';
  static AUTO_MARKET_DATA = 'AUTO_MARKET_DATA';
  static AUTO_REMOVE_GAME_DATA = 'AUTO_REMOVE_GAME_DATA';
  static AUTO_LEAGUE_SCORE_DATA = 'AUTO_LEAGUE_SCORE_DATA';
  static AUTO_LEAGUE_TABLE_DATA = 'AUTO_LEAGUE_TABLE_DATA';
  static OPENED_BETS_EVENT = 'OPENED_BETS_EVENT';
  static OPENED_BETS_COUNT_EVENT = 'OPENED_BETS_COUNT_EVENT';
  static CASH_OUT_SUBSCRIBE_EVENT = 'CASH_OUT_SUBSCRIBE_EVENT';
  static AUTO_GAME_DATA = 'AUTO_GAME_DATA';
  static AUTO_REMOVE_TENNIS_DATA = 'AUTO_REMOVE_TENNIS_DATA';
  static AUTO_TENNIS_UPDATE = 'AUTO_TENNIS_UPDATE';
  static LOGIN_DATA_EVENT = 'LOGIN_DATA_EVENT';
  static CASH_OUT_EVENT = 'CASH_OUT_EVENT';
  static AUTO_CASH_OUT_DATA = 'AUTO_CASH_OUT_DATA';
  static MAX_BET_EVENT = 'MAX_BET_EVENT';
  static UNSUBSCRIBE_EVENT = 'UNSUBSCRIBE_EVENT';
  static GAME_MATCH_ID_RESULTS_EVENT = 'GAME_MATCH_ID_RESULTS_EVENT';
  static GAME_RESULTS_EVENT = 'GAME_RESULTS_EVENT';

  static instance = null;

  constructor(siteID) {
    this.setupWebSocket(siteID);
  }

  static getInstance(siteID) {
    if (!RequestManager.instance) {
      RequestManager.instance = new RequestManager(siteID);
    }
    return RequestManager.instance;
  }

  getSocketURL(siteID) {
    const whiteIDs = [18746641, 1877183, 18747163, 18747874, 18749622, 333];
    if (whiteIDs.includes(parseInt(siteID))) {
      return OLD_SOCKET_URL;
    }

    return (Utils.STAGING_MODE /*|| Utils.DEV_MODE*/) ? SOCKET_URL_STAGING : SOCKET_URL;
  }

  setupWebSocket(siteID) {
    const socketURL = this.getSocketURL(siteID);
    webSocket = new WebSocket(socketURL);

    webSocket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      const requestID = response.rid.toString();
      let socketEvent;

      switch (requestID) {
        case SWARM_LOGIN_RID:
          socketEvent = new CustomEvent(RequestManager.SWARM_LOGIN_EVENT, {detail: response});
          break;
        case AUTHORIZATION_RID:
          socketEvent = new CustomEvent(RequestManager.AUTHORIZATION_EVENT, {detail: response});
          break;
        case RE_CAPTCHA_DATA_RID:
          socketEvent = new CustomEvent(RequestManager.RE_CAPTCHA_EVENT, {detail: response});
          break;
        case RE_CAPTCHA_MAX_DATA_RID:
          socketEvent = new CustomEvent(RequestManager.RE_CAPTCHA_MAX_EVENT, {detail: response});
          break;
        case CHANGE_LANG_RID:
          socketEvent = new CustomEvent(RequestManager.CHANGE_LANG_EVENT, {detail: response});
          break;
        case PARTNER_CONFIG_RID:
          socketEvent = new CustomEvent(RequestManager.PARTNER_DATA_EVENT, {detail: response});
          break;
        case SPORTS_RID:
          socketEvent = new CustomEvent(RequestManager.SPORTS_DATA_EVENT, {detail: response});
          break;
        case CURRENT_SPORT_RID:
          socketEvent = new CustomEvent(RequestManager.SPORT_DATA_UPDATED_EVENT, {detail: response});
          break;
        case GAME_RID:
          socketEvent = new CustomEvent(RequestManager.GAME_DATA_EVENT, {detail: response});
          break;
        case REGION_RID:
          socketEvent = new CustomEvent(RequestManager.REGION_DATA_EVENT, {detail: response});
          break;
        case GAME_RESULTS_RID:
          socketEvent = new CustomEvent(RequestManager.GAME_RESULTS_EVENT, {detail: response});
          break;
        case GAME_MATCH_ID_RESULT.rid:
          socketEvent = new CustomEvent(RequestManager.GAME_MATCH_ID_RESULTS_EVENT, {detail: response});
          break;
        case GAME_MATCH_ID_RESULT.rid:
          socketEvent = new CustomEvent(RequestManager.GAME_MATCH_ID_RESULTS_EVENT, {detail: response});
          break;
        case MARKET_RID:
          socketEvent = new CustomEvent(RequestManager.MARKET_DATA_EVENT, {detail: response});
          break;
        case LEAGUE_ROUNDS_RID:
          socketEvent = new CustomEvent(RequestManager.LEAGUE_ROUNDS_DATA_EVENT, {detail: response});
          break;
        case TENNIS_RID:
          socketEvent = new CustomEvent(RequestManager.TENNIS_DATA_EVENT, {detail: response});
          break;
        case CHECK_BET_RID:
          socketEvent = new CustomEvent(RequestManager.BET_CHECKED_EVENT, {detail: response});
          break;
        case LOGIN_DATA.rid:
          socketEvent = new CustomEvent(RequestManager.LOGIN_DATA_EVENT, {detail: response});
          break;
        case PROFILE_RID:
          socketEvent = new CustomEvent(RequestManager.PROFILE_DATA_EVENT, {detail: response});
          break;
        case PLACE_BETS_RID:
          socketEvent = new CustomEvent(RequestManager.PLACE_BETS_EVENT, {detail: response});
          break;
        case BET_HISTORY_RID:
          socketEvent = new CustomEvent(RequestManager.BET_HISTORY_EVENT, {detail: response});
          break;
        case OPENED_BETS_COUNT_RID:
          socketEvent = new CustomEvent(RequestManager.OPENED_BETS_COUNT_EVENT, {detail: response});
          break;
        case CASH_OUT_RID:
          socketEvent = new CustomEvent(RequestManager.CASH_OUT_EVENT, {detail: response});
          break;
        case CASH_OUT_SUBSCRIBE_DATA.rid:
          socketEvent = new CustomEvent(RequestManager.CASH_OUT_SUBSCRIBE_EVENT, {detail: response});
          break;
        case MAX_BET_RID:
          socketEvent = new CustomEvent(RequestManager.MAX_BET_EVENT, {detail: response});
          break;
        case OPENED_BETS_RID:
          socketEvent = new CustomEvent(RequestManager.OPENED_BETS_EVENT, {detail: response});
          break;
        case UNSUBSCRIBE_RID:
          socketEvent = new CustomEvent(RequestManager.UNSUBSCRIBE_EVENT, {detail: response});
          break;

        case AUTO_DATA_UPDATE_RID:
          this.handleAutoEvent(response);
          return;
        default:
          console.log('REQUEST ID NOT DEFINED: ', response);
      }

      if (socketEvent) {
        webSocket.dispatchEvent(socketEvent);
      }
    };

    webSocket.onclose = () => {
      webSocket.dispatchEvent(new CustomEvent(RequestManager.SOCKET_CLOSED_EVENT));
    };
  }


  handleAutoEvent({data}) {
    const dataObjects = Object.keys(data).map(key => data[key]);
    let dataObjectString;
    let event;

    dataObjects.map(dataObject => {
        dataObjectString = JSON.stringify(dataObject);

        if (dataObjectString.includes('update_state')) {
          event = new CustomEvent(RequestManager.AUTO_TENNIS_UPDATE, {detail: dataObject});
          webSocket.dispatchEvent(event);
        }

        if (dataObjectString.includes('market')) {
          event = new CustomEvent(RequestManager.AUTO_MARKET_DATA, {detail: dataObject});
        } else if ((dataObjectString.includes('homescore') || dataObjectString.includes('awayscore')) &&
          !dataObjectString.includes('game_number') && !dataObjectString.includes('null')) {
          event = new CustomEvent(RequestManager.AUTO_LEAGUE_SCORE_DATA, {detail: dataObject});
        } else if ((dataObjectString.includes('game') && !dataObjectString.includes('alias')) || dataObjectString.includes('game_number')) {
          event = new CustomEvent(RequestManager.AUTO_GAME_DATA, {detail: dataObject});
        } else if (dataObjectString.includes('competition') && dataObjectString.includes('TeamName')) {
          event = new CustomEvent(RequestManager.AUTO_LEAGUE_TABLE_DATA, {detail: dataObject});
        } else if (dataObjectString.includes('type')) {
          event = new CustomEvent(RequestManager.AUTO_REMOVE_GAME_DATA, {detail: dataObject});
        } else if (dataObjectString.includes('profile')) {
          event = new CustomEvent(RequestManager.AUTO_BALANCE_DATA, {detail: dataObject});
        } else if (dataObjectString.includes('cashout')) {
          event = new CustomEvent(RequestManager.AUTO_CASH_OUT_DATA, {detail: dataObject});
        }

        if (dataObjectString.includes('"sport":{"14":') && dataObjectString.includes('region')
          && dataObjectString.includes('competition') && dataObjectString.includes('market') && dataObjectString.includes('event')) {
          event = new CustomEvent(RequestManager.AUTO_REMOVE_TENNIS_DATA, {detail: dataObject});
        }

        try {
          webSocket.dispatchEvent(event);
        } catch (e) {
          console.log('>> FAILED DISPATCHING LIVE EVENT:', data ? data.data : data);
        }
      }
    )

  }

  get webSocket() {
    return webSocket;
  }
}