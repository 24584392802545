import Utils from "../utils/Utils";

export const AUTHORIZATION_RID = Utils.getUniqNumber().toString();
export const SPORTS_RID = Utils.getUniqNumber().toString();
export const GAME_RID = Utils.getUniqNumber().toString();
export const REGION_RID = Utils.getUniqNumber().toString();
export const RE_CAPTCHA_DATA_RID = Utils.getUniqNumber().toString();
export const RE_CAPTCHA_MAX_DATA_RID = Utils.getUniqNumber().toString();
export const GAME_RESULTS_RID = Utils.getUniqNumber().toString();
export const CURRENT_SPORT_RID = Utils.getUniqNumber().toString();
export const MARKET_RID = Utils.getUniqNumber().toString();
export const LEAGUE_ROUNDS_RID = Utils.getUniqNumber().toString();
export const CHECK_BET_RID = Utils.getUniqNumber().toString();
export const PLACE_BETS_RID = Utils.getUniqNumber().toString();
export const BET_HISTORY_RID = Utils.getUniqNumber().toString();
export const OPENED_BETS_COUNT_RID = Utils.getUniqNumber().toString();
export const OPENED_BETS_RID = Utils.getUniqNumber().toString();
export const PARTNER_CONFIG_RID = Utils.getUniqNumber().toString();
export const TENNIS_RID = Utils.getUniqNumber().toString();
export const PROFILE_RID = Utils.getUniqNumber().toString();
export const CASH_OUT_RID = Utils.getUniqNumber().toString();
export const MAX_BET_RID = Utils.getUniqNumber().toString();
export const CHANGE_LANG_RID = Utils.getUniqNumber().toString();
export const UNSUBSCRIBE_RID = Utils.getUniqNumber().toString();

export const AUTO_DATA_UPDATE_RID = "0";
export const SWARM_LOGIN_RID = '1';


const AUTHORIZATION_DATA = {
  command: "request_session",
  params: {
    site_id: "1",
    language: "eng"
  },
  rid: AUTHORIZATION_RID,
  requestName: 'AUTHORIZATION_DATA'
};

const CHANGE_LANG_DATA = {
  command: "change_session_language",
  params: {
    language: ''
  },
  rid: CHANGE_LANG_RID
};

const LOGIN_DATA = {
  command: "restore_login",
  params: {
    user_id: "",
    auth_token: "F79EBA818616C513A7C2AA66C63FBC4A"
  },
  rid: null
}

const LOGOUT_DATA = {
  command: "logout",
  params: {},
  rid: null
}

const MAX_BET_DATA = {
  command: "get_max_bet",
  params: {
    type: 1,
    each_way: false,
    events: [2060437739, 2060438579]
  },
  rid: MAX_BET_RID
}

const PROFILE_DATE = {
  command: "get",
  params: {
    source: "user",
    what: {
      profile: []
    },
    subscribe: true
  },
  rid: PROFILE_RID
}

const PARTNER_CONFIG_DATA = {
  command: "get",
  params: {
    "source": "partner.config",
    "what": {"partner": []},
    "subscribe": true
  },
  rid: PARTNER_CONFIG_RID
}

const SWARM_LOGIN_DATA = {
  command: "login",
  params: {
    "username": "anushavan.babayan@betconstruct.com",
    "password": "Anush1988"
  },
  rid: SWARM_LOGIN_RID
}

const TENNIS_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      game: ["game_number", "team1_name", "team2_name", "id", "start_ts", "update_state", "text_info", "info",
        "round", "home_score", "away_score"]
    },
    where: {
      competition: {id: 30}
    },
    subscribe: true
  },
  rid: TENNIS_RID,
  subID: null,
  requestName: 'TENNIS_DATA'
}

const SPORTS_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      sport: ["id", "name", "alias", "order"],
      game: ["@count", "start_ts", "text_info"],
      region: ["id"],
    },
    where: {
      game: {
        "@or": [
          {
            type: 0
          },
          {
            visible_in_prematch: true,
            type: 4
          }]
      },
      sport: {
        id: {
          "@in": [1, 2, 3, 4, 6, 7, 9, 12, 13, 14]
        }
      }
    },
    subscribe: true
  },
  rid: SPORTS_RID,
  subID: null,
  requestName: 'SPORTS_DATA'
}

const SUBSCRIBE_TO_SPORT_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {"competition": ["id", "name", "order", "info", "video_id", "provider"]},
    where: {game: {"@or": [{"type": 0}, {"visible_in_prematch": true, "type": 4}]}, sport: {"id": 7}},
    subscribe: true
  },
  rid: CURRENT_SPORT_RID,
  subID: null,
  requestName: 'SUBSCRIBE_TO_SPORT_DATA'
};

const UNSUBSCRIBE = {
  command: "unsubscribe",
  params: {subid: "6166728670616548707"},
  rid: UNSUBSCRIBE_RID
}

const GAME_DATA = {
  command: "video_url",
  params: {
    video_id: 9,
    provider: 15,
    use_hls: true
  },
  rid: GAME_RID,
  requestName: 'GAME_DATA'
}

const REGION_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      region: ["id", "name"],
      competition: ["id", "name"],
    },
    where: {
      sport: {
        id: 1,
      },
    },
  },
  rid: REGION_RID,
};

const GAME_RESULTS_DATA = {
  command: "get_result_games",
  params: {
    is_date_ts: 1,
    sport_id: 1,
    competition_id: 1,
    from_date: 1,
    to_date: 1,
  },
  rid: GAME_RESULTS_RID,
};

const GAME_MATCH_ID_RESULT = {
  command: "get_results",
  params: {
    game_id: 1507476873,
  },
  rid: "10925654702008.588",
};

const MARKET_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      "sport": ["id", "alias"],
      "competition": ["id", "name"],
      "region": ["id"],
      "game": [],
      "market": [],
      "event": []
    },
    where: {
      "game": {"id": 1123279},
      "sport": {"id": 1}, "region": {"id": 1}
    },
    subscribe: true
  },
  rid: MARKET_RID,
  subID: null,
  requestName: 'MARKET_DATA'
}


const LEAGUE_ROUNDS_DATA = {
  command: "get",
  params: {
    source: "betting",
    what: {
      game: ["game_number", "team1_name", "team2_name", "id", "start_ts", "text_info", "info", "tv_type", "round", "homescore", "awayscore"]
    },
    where: {
      competition: {id: 20},
      game: {"@or": [{"type": 0}, {"visible_in_prematch": true, "type": 4}]}
    },
    subscribe: true
  },
  rid: LEAGUE_ROUNDS_RID,
  subID: null,
  requestName: 'LEAGUE_ROUNDS_DATA'
}

const BET_CHECK_DATA = {
  command: "get",
  params: {
    source: "betting",
    is_betslip: true,
    what: {
      game: ["id", "is_blocked", "team1_name", "team2_name", "team1_reg_name", "team2_reg_name", "is_live"],
      market: ["base", "type", "name", "home_score", "away_score", "cashout", "extra_info"],
      event: ["id", "price", "type", "type_1", "name", "base", "ew_allowed"]
    },
    where: {
      game: {id: {"@in": []}},
      event: {id: {"@in": []}},
      market: {id: {"@in": []}}
    },
    subscribe: true
  },
  rid: CHECK_BET_RID,
  subID: null,
  requestName: 'BET_CHECK_DATA'
}

const RE_CAPTCHA_DATA = {
  command: "validate_recaptcha",
  params: {
    action: "do_bet",
    g_recaptcha_response: "03AGdBq25FtdF4fH3RrHXRcgcli6ucLVTde6mtXDz8IWkt3U4X-eFfUCURSNWjM94TGhWwl7m-JXan9Qq4xD_lekAvmCFTk_St6ILxlShbE0Fr0u6lMvLTPorg359lLdubQIC3OFpg7J6pCUr_2w7uAOcfkUxRiLNFnHTCXThi0OuP3L1VB78SdKI7a0oq3OfoXewCaULkGC9jQOg1JDykjQNJhuKDgzAs5Y3oZSuKP-ffF4qV15hbJ-nCQ5JFDJix8U43vAOmk1OA-h7wcNvN36MoAMkbb-mTSNML224w_LUPRNgWejweEEBOZxo4FOi3oRO9hDtuunvt3deNNkM6fZLkMu4nXLwV1OgbfZKlLf8TwfHZz5AkINDtHDEmC8xQCOH8GucYtyuAJ7zQi9c-E0i18YGMVEVtFx9iLPqtoMfW7Xax2ckzYPU",
  },
  rid: RE_CAPTCHA_DATA_RID,
  requestName: 'RE_CAPTCHA_DATA'
}

const RE_CAPTCHA_MAX_DATA = {
  command: "validate_recaptcha",
  params: {
    action: "get_max_bet",
    g_recaptcha_response: "03AGdBq25FtdF4fH3RrHXRcgcli6ucLVTde6mtXDz8IWkt3U4X-eFfUCURSNWjM94TGhWwl7m-JXan9Qq4xD_lekAvmCFTk_St6ILxlShbE0Fr0u6lMvLTPorg359lLdubQIC3OFpg7J6pCUr_2w7uAOcfkUxRiLNFnHTCXThi0OuP3L1VB78SdKI7a0oq3OfoXewCaULkGC9jQOg1JDykjQNJhuKDgzAs5Y3oZSuKP-ffF4qV15hbJ-nCQ5JFDJix8U43vAOmk1OA-h7wcNvN36MoAMkbb-mTSNML224w_LUPRNgWejweEEBOZxo4FOi3oRO9hDtuunvt3deNNkM6fZLkMu4nXLwV1OgbfZKlLf8TwfHZz5AkINDtHDEmC8xQCOH8GucYtyuAJ7zQi9c-E0i18YGMVEVtFx9iLPqtoMfW7Xax2ckzYPU",
  },
  rid: RE_CAPTCHA_MAX_DATA_RID,
  requestName: 'RE_CAPTCHA_DATA'
}

const PLACE_BETS_DATA = {
  command: "do_bet",
  params: {
    type: 1,
    mode: 0,
    tags: 0,
    odd_type: 0,
    is_offer: 0,
    amount: 50,
    source: "4",
    each_way: false,
    bets: []
  },
  rid: PLACE_BETS_RID
}

const BET_HISTORY_DATA = {
  command: "bet_history",
  params: {
    where: {
      from_date: 1600718400,
      to_date: 1602619199
    }
  },
  rid: BET_HISTORY_RID
}

const OPENED_BETS_DATA = {
  command: "bet_history",
  params: {
    where: {"outcome": 0}
  },
  rid: OPENED_BETS_RID
}

const CASH_OUT_DATA = {
  command: "cashout",
  params: {
    bet_id: 1417233517,
    price: 41.3,
    mode: 0
  },
  rid: CASH_OUT_RID
}

const CASH_OUT_SUBSCRIBE_DATA = {
  command: "get",
  params: {
    source: "notifications",
    what: {user: []},
    subscribe: true
  },
  rid: null,
  subID: null,
  requestName: 'CASH_OUT_SUBSCRIBE_DATA'
}

export {
  AUTHORIZATION_DATA,
  SPORTS_DATA,
  GAME_DATA,
  SUBSCRIBE_TO_SPORT_DATA,
  MARKET_DATA,
  LEAGUE_ROUNDS_DATA,
  BET_CHECK_DATA,
  RE_CAPTCHA_DATA,
  RE_CAPTCHA_MAX_DATA,
  PLACE_BETS_DATA,
  SWARM_LOGIN_DATA,
  BET_HISTORY_DATA,
  PARTNER_CONFIG_DATA,
  TENNIS_DATA,
  OPENED_BETS_DATA,
  LOGIN_DATA,
  PROFILE_DATE,
  CASH_OUT_DATA,
  CASH_OUT_SUBSCRIBE_DATA,
  LOGOUT_DATA,
  MAX_BET_DATA,
  CHANGE_LANG_DATA,
  UNSUBSCRIBE,
  GAME_RESULTS_DATA,
  GAME_MATCH_ID_RESULT,
  REGION_DATA
};